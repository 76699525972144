import React,{useEffect} from "react";
// import { useParallax } from "react-scroll-parallax";
import AOS from 'aos';
import 'aos/dist/aos.css';

import BlueBG from '../assets/image/hero-blur.png';
import Avatar from '../assets/image/avatar.png';
import Interfaces from '../assets/image/interfaces.svg';
import Analytics from '../assets/image/analytics.svg';
import UserExperience from '../assets/image/userexperience.svg';



export default function Hero () {
    useEffect(() => {
        // const parallax = useParallax({
        //     translateX: [0, 1100, "easeOutQuint"],
        //     translateY: [-10, 1100, "easeInQuint"],
        //  });

        AOS.init();
      }, []);

    //   const parallax = useParallax({
    //     translateX: [0, 1100, "easeOutQuint"],
    //     translateY: [-10, 1100, "easeInQuint"],
    //   });
    //   const parallax2 = useParallax({
    //     translateX: [-200, 1100, "easeOutQuint"],
    //     translateY: [-10, 1100, "easeInQuint"],
    //   });
   return(
    <>
        <section className="hero-bannerSection">
            <div className="container">
                <div className="wrap-bannerSection">
                    <div className="grandient-bg" data-aos="fade-up">
                        <img
                            src={BlueBG}
                            alt=""
                        />
                    </div>
                    <div className="hero-bannerContent">
                        <div className="row">
                            <div className="col-7">
                                <div  className="content-left">
                                    <div className="avatar">
                                        <img
                                            src={Avatar}
                                            data-aos="fade-up"
                                            alt="" 
                                        />
                                    </div>
                                    <div data-aos="fade-up" className="banner-content">
                                        <h1>Hi, I am Sujam - Creative Head</h1>
                                        <p>I enjoy creating products from scratch and improve existing ones. In simple terms, I design Product that make a profit or enhance business.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div data-aos="fade-left" className="hero-elements">
                        <img 
                            className="elements1"
                            data-aos="fade-up-left"
                            src={Analytics } 
                            // ref={parallax.ref}
                            alt="" 
                        />

                        <img 
                            className="elements2"
                            data-aos="fade-down-left"
                            src={UserExperience} 
                            // ref={parallax2.ref}
                            alt="" 
                        />

                        <img 
                            className="elements3"
                            data-aos="fade-down-left"
                            src={Interfaces} 
                            // ref={parallax.ref}
                            alt="" 
                        />
                    </div>
                    <div className="button" data-aos="fade-up">
                        <button>Let's Talk</button>
                    </div>
                </div>
            </div>
        </section>
    </>
   );
}


