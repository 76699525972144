
import './bootstrap.css';
import './App.scss';

import Navbar from './components/Navbar';

import Hero from './components/Hero';

// import Mint from './components/mint';

// import About from './components/About';

// import Ecosystem from './components/Ecosystem';

// import Gallery from './components/Gallery';

// import Team from './components/Team';

// import Contact from './components/Contact';

// import Footer from './components/Footer';


export const App = () => {
  return (
    <div className='App'>

      {/* Header */}
      <Navbar />
      <Hero />

  
    </div>
  );
}

export default App;
